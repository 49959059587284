<template>
  <div class="fix-min-height d-flex flex-column justify-content-center">
    <div v-if="contract.contractType !== 'SC'">
      <div v-for="(field, index) in displayFields" :key="index">
        <b-row class="justify-content-between d-flex f-14">
          <b-col cols="6">
            <p>{{ field.label }} :</p></b-col
          >
          <b-col cols="6">
            <p
              class="f-medium text-end"
              ref="fieldLabel"
              :class="{
                'f-orange': field.label == 'สถานะสัญญา',
                'text-truncate': !wrapState[index],
                'text-wrap': wrapState[index]
              }"
              @click="toggleWrap(index)"
            >
              {{ field.value || "-" }}
            </p></b-col
          >
        </b-row>
        <hr v-if="index < displayFields.length - 1" class="my-1" />
      </div>
    </div>
    <div v-else class="font-weight-bold f-14 w-100 text-center">
      <p>ท่านมีสัญญา SKL การ์ด</p>
      <p>หากต้องการใช้งานบัตรๆ</p>
      <p>กรุณาใช้งานผ่าน SKL โมบายแอปพลิเคชัน</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
  },
  data: () => {
    return {
      wrapState: [],
      callCenter: "",
      contractInfo: {},
      toDay: "",
      displayFields: [],
      contractTypeText: {
        HP: "สินเชื่อเช่าซื้อ",
        FL: "สินเชื่อลีสซิ่ง",
        TL: "สินเชื่อรถเติมสุข",
        PL: "สินเชื่อเติมฝัน",
        SC: "SKL Card"
      },
      contractStatusText: {
        A: "ปกติ",
        C: "ปิดสัญญา",
        SC: "SKL Card",
        L: "ค้างชำระ",
        SP: "-",
      }
    };
  },
  watch: {
    displayFields: {
      immediate: true,
      handler(newValue) {
        this.wrapState = newValue.map(() => false);
      }
    }
  },
  created() {
    // if (this.contract && this.contract.ContractType !== "SC") {
    //   this.getContractDetail(this.contract.contractNo);
    // }
    this.toDay = this.formatToThaiDate(new Date());
  },
  methods: {
    formatToThaiDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
    async getContractDetail(contractNo) {
      this.$isLoadingAlpha = true;
      var raw = JSON.stringify({
        ContractNo: contractNo,
        LineId: this.lineProfile.userId
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${this.$API_DEV_LINE_SKL}/Contract/info`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.contractInfo = result.detail;
            this.displayFields = [
              { label: "เลขที่สัญญา", value: this.contractInfo.contractNo },
              {
                label: "ประเภทสัญญา",
                value: this.contractTypeText[this.contractInfo.contractType]
              },
              { label: "รุ่นรถ", value: this.contractInfo.model },
              { label: "เลขป้ายทะเบียน", value: this.contractInfo.licenceNo },
              {
                label: "วันที่จดทะเบียน",
                value: this.contractInfo.asOfDateDisplay
              },
              {
                label: "วันที่เริ่มสัญญา",
                value: this.contractInfo.bookingDateDateDisplay
              },
              {
                label: "กำหนดชำระทุกวันที่",
                value: this.contractInfo.dueDate
              },
              {
                label: "สถานะสัญญา",
                value:
                  this.contractStatusText[
                    this.contractInfo.collectionStatusUnits
                  ]
              }
            ];
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    toggleWrap(index) {
      this.$set(this.wrapState, index, !this.wrapState[index]);
    }
  }
};
</script>

<style scoped>
.card-custom {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.txt-note {
  font-size: 12px;
}
.display-inline {
  display: inline-flex;
}

.fix-min-height {
  min-height: 200px;
  height: 100%;
}
</style>
