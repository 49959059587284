<template>
  <div class="insallment-body w-100">
    <div class="px-4">
      <ListHeader
        :profile="customerProfile"
        :lineProfile="lineProfile"
        class="mt-4"
      />
      <div
        v-if="!isVerify || !isHaveContract"
        class="px-4 py-5 mt-2 text-center verify-body justify-content-center d-flex"
      >
        <div>
          <div v-if="!customerProfile.idCard">
            <p class="mt-4 f-orange font-weight-bold">
              ไม่พบข้อมูลการลงทะเบียนของท่าน
            </p>
            <p class="mt-1 f-orange font-weight-bold">
              กรุณาลงทะเบียนก่อนเข้าใช้งาน
            </p>
            <!-- <router-link :to="'/register'">
              <b-button
                variant="submit"
                class="btn-submit w-100 mt-4"
                @click="$router.push({ path: '/register', replace: true })"
                >ลงทะเบียน</b-button
              >
            </router-link> -->
          </div>
          <div v-else-if="!isHaveContract">
            <div class="mt-4 f-orange font-weight-bold">
              ยังไม่พบข้อมูลสัญญาสินเชื่อของท่านในขณะนี้
            </div>
            <div class="mt-4 f-orange font-weight-bold">
              หากมีข้อสงสัยโทร
              <a
                ><span
                  v-for="(c, i) in '1317'"
                  :key="i"
                  class="link-tel"
                  :class="[{ 'link-tel-green': c == '3' }, 'link-tel']"
                  >{{ c }}<span class="link-tel-green"></span></span
              ></a>
            </div>
          </div>

          <div v-else>
            <div v-if="!otpState.prefix">
              <div class="mt-4 f-orange font-weight-bold">
                พบข้อมูลสัญญาสินเชื่อของท่าน
              </div>
              <div>
                <b-button
                  class="mx-1 btn-submit w-100 px-3 mt-5"
                  ref="tabButton"
                  @click="sendOTP()"
                  >ยืนยันตัวตน</b-button
                >
              </div>
            </div>
            <div v-else>
              <FormOTP
                id="put-OTP"
                v-model="otpNumber"
                placeholder="ํOTP"
                type="number"
                pattern="\d*"
                inputmode="numeric"
                :isError="otpState.isError"
                :MobileNumber="customerProfile.mobileNumber"
                :prefix="otpState.prefix"
                @sendOTPAgain="sendOTP"
              />
              <div id="otp-error" class="text-center mt-3 f-14 w-100">
                <font color="red">{{ otpState.otpMessage }}</font>
              </div>

              <b-button
                id="submit-OTP"
                variant="submit"
                class="btn-submit w-100 mt-4"
                @click="submitOTP"
                :disabled="otpState.waiting"
                >ยืนยัน</b-button
              >
            </div>
          </div>
          <p class="txt-tel mt-5">
            สอบถามข้อมูลเพิ่มเติมโทร.
            <a :href="`tel:${callCenter}`"
              ><span
                v-for="(c, i) in callCenter"
                :key="i"
                class="link-tel"
                :class="[{ 'link-tel-green': c == '3' }, 'link-tel']"
                >{{ c }}<span class="link-tel-green"></span></span
            ></a>
          </p>
        </div>
      </div>
      <div v-else>
        <div>
          <p class="mt-2 f-16 font-weight-bold">สัญญาสินเชื่อของท่าน</p>

          <div
            class="scroll-container d-flex flex-nowrap mt-2"
            ref="scrollContainer"
            @scroll="onScroll"
          >
            <div
              class="card card-slide p-sm-3 p-2 mr-2 d-flex align-items-start w-100"
              ref="contractList"
              v-for="(contract, index) in contractList"
              :key="index"
              :class="{ 'active-card': currentIndex === index }"
              @click="selectContract(contract, index)"
            >
              <b-row class="justify-content-between w-100 m-0">
                <b-col cols="7" class="my-auto">
                  <!-- Contract Details -->
                  <p class="f-14">
                    ประเภทสัญญา : {{ contractTypeText[contract.contractType] }}
                  </p>
                  <p class="f-14">
                    เลขที่สัญญา : {{ contract.contractNoDisplay }}
                  </p>
                  <p class="f-14">{{ contract.modelName }}</p>
                  <p
                    class="f-14 text-wrap"
                    v-if="contract.contractType !== 'SC'"
                  >
                    รุ่นรถ : {{ contract.model }}
                  </p>
                </b-col>
                <b-col cols="5" class="d-flex justify-content-center my-auto">
                  <!-- Contract Image -->
                  <img v-if="contract.photo"
                    class="img-size"
                    :src="contract.photo"
                    alt="Product Image"
                  />
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="indicator-container d-flex justify-content-center">
            <span
              v-for="(contract, index) in contractList"
              :key="index"
              :class="[
                'indicator-dot',
                { 'active-dot': currentIndex === index }
              ]"
              @click="scrollToIndex(index)"
            >
            </span>
          </div>
        </div>
        <div class="submenu-tap d-flex py-2 mt-2 justify-content-md-center">
          <b-button
            v-for="(item, index) in subMenu1"
            :disabled="currentContract.contractType == 'SC' && index !== 0"
            :key="index"
            class="f-12 submenu1-class p-1 mx-1"
            :class="{
              'btn-green': tabIndex === item.tabIndex,
              'btn-white': tabIndex !== item.tabIndex
            }"
            ref="tabButton"
            @click="selectTap(item.tabIndex)"
            >{{ item.text }}
          </b-button>
        </div>
        <div id="content-installment">
          <b-card class="content-tab">
            <div v-if="tabIndex == '0'" class="px-3">
              <ContractDetail
                ref="contractDetail"
                :profile="profile"
                :lineProfile="lineProfile"
                :contract="currentContract"
              ></ContractDetail>
            </div>
            <div v-else-if="tabIndex == '1'">
              <InstallmentDetail
                ref="installmentDetail"
                :profile="profile"
                :contract="currentContract"
                :lineProfile="lineProfile"
                :displayContract="currentContract.contractNoDisplay"
              ></InstallmentDetail>
            </div>
            <div
              v-else-if="tabIndex == '2'"
              class="d-flex flex-column justify-content-between text-center align-items-center family-container"
            >
              <div class="f-14 f-navy">
                SKL แฟมิลี่คลับ : บริการสะสมคะแนน SKL Point <br />
                จากยอดชำระเงินค่างวด* เพื่อแลกรับสิทธิประโยชน์ และของที่ระลึก
                <br />
                500 บาท = 1 คะแนน
              </div>
              <div>
                <b-button
                  class="mt-4 btn-green w-100 px-5 f-14"
                  id="submit-register "
                  @click="openFamilyClub(1)"
                  :disabled="buttonLoading"
                  >เช็กคะแนนและแลกรับสิทธิพิเศษ</b-button
                >
              </div>
              <div class="mt-2">
                <p class="txt-tel">
                  สอบถามข้อมูลเพิ่มเติมโทร.
                  <a :href="`tel:${callCenter}`"
                    ><span
                      v-for="(c, i) in callCenter"
                      :key="i"
                      class="link-tel"
                      :class="[{ 'link-tel-green': c == '3' }, 'link-tel']"
                      >{{ c }}<span class="link-tel-green"></span></span
                  ></a>
                </p>
              </div>
            </div>
            <div v-else-if="tabIndex == '3'">
              <InstallmentHistory
                ref="installmentHistory"
                :contractNo="contractNo"
                :lineProfile="lineProfile"
              ></InstallmentHistory>
            </div>
            <div v-else-if="tabIndex == '4'">
              <div class="justify-content-between d-flex px-4 f-14">
                <p>เลขที่สัญญา :</p>
                <p class="f-medium">{{ profile.contractNo }}</p>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :busy="isBusy"
                show-empty
                empty-text="ไม่พบข้อมูล"
                class="greenColor mt-sm-3 mt-2"
              >
                <template v-slot:cell(inst_Tot_Amt)="data">
                  <p class="mb-0 text-right">
                    {{
                      Number(data.item.inst_Tot_Amt)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    }}
                  </p>
                </template>
                <template v-slot:cell(act_Tot_Amt)="data">
                  <p class="mb-0 text-right">
                    {{
                      Number(data.item.act_Tot_Amt)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    }}
                  </p></template
                >
              </b-table>
              <p class="mb-0 f-12 f-orange">
                ข้อมูล ณ วันที่ {{ profile.asOfDate || "-" }} <br />
              </p>
              <div class="display-inline w-100">
                <span class="txt-note"
                  >หมายเหตุ : ข้อมูลที่แสดงในตารางเป็นข้อมูลค่างวดย้อนหลัง 3
                  เดือน และข้อมูลค่างวดล่วงหน้า 3 เดือน</span
                >
              </div>
            </div>
            <div v-else-if="tabIndex == '5'">
              <PaymentInvoice
                ref="paymentInvoice"
                :contractNo="contractNo"
                :lineProfile="lineProfile"
                :displayContract="currentContract.contractNoDisplay"
              ></PaymentInvoice>
            </div>
          </b-card>
          <div
            v-if="currentContract.contractType !== 'SC'"
            class="d-flex w-100 justify-content-center"
          >
            <b-button
              ref="buttonBarcode"
              class="rounded-pill w-50 f-12 mr-2 mx-0 my-0 mt-2"
              @click="openQrcode()"
              :class="{ 'btn-green w-50': openQr, 'btn-white w-50': !openQr }"
              :disabled="
                buttonLoading ||
                this.currentContract.collectionStatusUnits == 'C'
              "
              >สร้างบาร์โคด/คิวอาร์โคด</b-button
            >

            <b-button
              @click="handleOpenPayment()"
              :class="{
                'btn-green w-50': openPayment,
                'btn-white w-50': !openPayment
              }"
              class="rounded-pill w-50 f-12 m-0 mt-2"
              :disabled="
                buttonLoading ||
                this.currentContract.collectionStatusUnits == 'C'
              "
              >ชำระเงินออนไลน์</b-button
            >
          </div>
          <div v-else class="d-flex w-100 justify-content-center">
            <b-button
              @click="downloadSKL()"
              class="rounded-pill w-50 f-12 m-0 mt-2 btn-white w-100 f-green"
              :disabled="buttonLoading"
              >ดาวโหลด SKL โมบายแอปพลิเคชัน</b-button
            >
          </div>
          <b-collapse id="collapseQr" ref="collapseQr">
            <b-card class="mt-2 barcode-card">
              <QRcode
                ref="qrCode"
                :profile="profile"
                :contract="currentContract"
                :lineProfile="lineProfile"
              ></QRcode>
            </b-card>
          </b-collapse>
          <b-collapse id="collapse-2" ref="collapsePayment">
            <b-card class="mt-2 payment-card">
              <OnlinePayment
                ref="onlinePayment"
                :idCard="customerProfile.idCard"
                :contract="currentContract"
                :lineProfile="lineProfile"
              ></OnlinePayment>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstallmentHistory from "./components/InstallmentHistory.vue";
import ListHeader from "./ListHeader";
import PaymentInvoice from "./components/PaymentInvoice.vue";
import InstallmentDetail from "./components/InstallmentDetail.vue";
import QRcode from "./components/QRcode.vue";
import ContractDetail from "./components/ContractDetail.vue";
import OnlinePayment from "./components/OnlinePayment.vue";
const FormOTP = () => import("@/components/panels/formOTP");

export default {
  components: {
    ListHeader,
    InstallmentHistory,
    FormOTP,
    PaymentInvoice,
    InstallmentDetail,
    QRcode,
    ContractDetail,
    OnlinePayment
  },
  data: () => {
    return {
      contractNo: "",
      lineId: "",
      lineProfile: {},
      profile: {
        asOfDate: "",
        contractNo: "",
        identificationNo: "",
        customerName: "",
        photo: ""
      },
      callCenter: "1317",
      isBusy: false,
      items: [],
      tabIndex: "0",
      otpNumber: "",
      fields: [
        {
          key: "term_No",
          label: "งวดที่",
          class: "text-center align-middle"
        },

        {
          key: "due_Date",
          label: "กำหนดชำระ",
          class: "text-center align-middle"
        },
        {
          key: "inst_Tot_Amt",
          label: "ค่างวด",
          class: "text-center align-middle"
        },
        {
          key: "act_Tot_Amt",
          label: "ยอดเงินที่ต้องชำระ (บาท)",
          class: "text-center align-middle ",
          thStyle: { width: "30%" }
        }
      ],
      slides: [
        { title: "Slide 1", image: "https://via.placeholder.com/800x400" },
        { title: "Slide 2", image: "https://via.placeholder.com/800x400" },
        { title: "Slide 3", image: "https://via.placeholder.com/800x400" }
      ],
      subMenu1: [
        { text: "ข้อมูลสัญญา", tabIndex: "0" },
        { text: "ข้อมูลค่างวด", tabIndex: "1" },
        { text: "แฟมิลี่คลับ", tabIndex: "2" },
        { text: "ประวัติชำระ", tabIndex: "3" },
        { text: "ตารางค่างวด", tabIndex: "4" },
        { text: "ใบแจ้งชำระ", tabIndex: "5" }
      ],
      isHaveContract: false,
      isVerify: false,
      customerProfile: {},
      otpState: {
        isError: false,
        otpMessage: "",
        prefix: "",
        waiting: false
      },
      buttonLoading: false,
      firstContractNo: "",
      contractList: [],
      currentIndex: 0,
      currentContract: {
        contractNoDisplay: "",
        contractNo: "",
        model: "",
        modelName: null,
        licenceNo: "",
        customerName: "",
        collectionStatusUnits: "",
        photo: "",
        contractType: "",
        asofDate: ""
      },
      contractTypeText: {
        HP: "สินเชื่อเช่าซื้อ",
        FL: "สินเชื่อลีสซิ่ง",
        TL: "สินเชื่อรถเติมสุข",
        PL: "สินเชื่อเติมฝัน",
        SC: "SKL Card"
      },
      openQr: false,
      openPayment: false
    };
  },
  beforeCreate() {
    this.tabIndex = this.$route.params.id;
    let liffId = "";
    if (window.location.hostname !== "dev-line-eservice.skl.co.th") {
      liffId = "1654133345-kyzZWPD9";
    } else liffId = this.$liff_ID_InstallmentList;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;

              this.checkTerm(profile.userId);
              this.getCustomerProfile();

              // this.checkConsent();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
            this.getCustomerProfile();

            // this.checkConsent();
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    this.$isLoadingAlpha = true;
    document.body.classList.add("bg-body");
    this.updateTitle();
  },
  mounted() {
    document.body.classList.add("bg-body");
  },

  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    CheckContract() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/CheckContract/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.isHaveContract = result.detail.isHaveContract;
            if (!this.isVerify) {
              this.$router.push("/register");
            }
          }
        })
        .catch(error => console.log("error", error));
    },
    async selectContract(selectContract, index) {
      await this.getInstallmentList(selectContract);

      const tab = this.$refs.contractList[index];
      this.openQr = false;
      this.$refs.collapseQr.show = false;
      this.openPayment = false;
      this.$refs.collapsePayment.show = false;

      this.currentContract = selectContract;
      if (tab) {
        tab.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    },
    async getInstallmentList(selectContract, selectTab) {
      if (selectContract) {
        this.contractNo = selectContract.contractNo;
        this.currentContract = selectContract;
      } else {
        this.contractNo = this.currentContract.contractNo;
      }

      this.tabIndex = this.$route.params.id;

      if (this.currentContract.contractType !== "SC") {
        this.$nextTick(() => {
          if (this.tabIndex == "0") {
            this.$refs.contractDetail.getContractDetail(this.contractNo);
          } else if (this.tabIndex == "1") {
            this.$refs.installmentDetail.getContratctInstallmentDetail(
              this.contractNo
            );
          } else if (this.tabIndex == "3") {
            this.$refs.installmentHistory.getPaymentHistoryList();
          } else if (this.tabIndex == "4") {
            this.getInstalmentTable();
          } else if (this.tabIndex == "5") {
            this.$refs.paymentInvoice.getInvoiceList();
          }
        });
      } else {
        if (this.$route.params.id !== "0") {
          this.$router.push(`/installment/0`);
        }

        this.tabIndex = "0";
        // this.$refs.contractDetail.getContractDetail(this.contractNo);
      }

      if (!selectTab) {
        this.$refs.qrCode.getContactBarcodeImg(
          this.contractNo,
          this.currentContract.contractType
        );
        this.$refs.qrCode.getBarcodePayment(
          this.contractNo,
          this.currentContract.contractType
        );
        // this.$refs.onlinePayment.getPaymentAmount(this.contractNo);

        this.$refs.onlinePayment.getOnlinePayment(
          this.contractNo,
          this.currentContract.contractType
        );
      }
      this.$isLoadingAlpha = false;
    },
    async getContractList() {
      this.$isLoadingAlpha = true;

      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/Contract/ContractList/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.contractList = result.detail;
            this.currentContract = this.contractList[0];
            console.log(this.currentContract);
            this.getInstallmentList();
    
          }
        })
        .catch(error => {
          this.isBusy = false;
          this.items = [];
          console.error(error);
        });

      this.$isLoadingAlpha = false;
    },
    getInstalmentTable() {
      this.isBusy = true;
      this.$isLoadingAlpha = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        LineId: this.lineProfile.userId,
        ContractNo: this.currentContract.contractNo
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/contract/paymentinstallment`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.items = result.detail.detail;
            this.profile.asOfDate = result.detail.asOfDate;
            this.profile.contractNo = result.detail.contractNo;
            this.profile.customerName = result.detail.customerName;
            this.callCenter = result.detail.callCenter;
            this.profile.photo = result.detail.photo;
            this.isBusy = false;
            // console.log(this.$refs.contractDetail);
            // if (this.$refs.contractDetail)
            //   this.$refs.contractDetail.getContractDetail(this.currentContract.contractNo);
          }
        })
        .catch(error => {
          this.isBusy = false;
          // this.items = [];
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    selectTap(index) {
      this.tabIndex = index;
      this.$router.push(`/installment/${index}`);
      this.openQr = false;
      this.$refs.collapseQr.show = false;
      this.openPayment = false;
      this.$refs.collapsePayment.show = false;
      const button = this.$refs.tabButton[index];
      if (button) {
        button.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
      this.getInstallmentList(null, 1);
    },
    async getCustomerProfile() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/profile/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.isVerify = result.detail.profile.isVerify;
            this.customerProfile = result.detail.profile;
            this.$emit("event-from-installment", this.lineProfile.userId);
            if (!this.isVerify) {
              this.CheckContract();
            } else {
              this.isHaveContract = true;
              this.getContractList();
            }
          } else {
            this.$router.push("/register");
          }
          this.$isLoadingAlpha = false;
        })
        .catch(error => console.log("error", error));
    },
    sendOTP() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.mobileNumber.slice(1)}`,
        LineId: this.lineProfile.userId,
        IdCard: this.customerProfile.IDCard
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(`${this.$API_DEV_LINE_SKL}/OTP`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.otpState.prefix = result.detail.result;
        })
        .catch(error => {
          console.error(error);
        });
    },
    submitOTP() {
      this.buttonLoading = true;
      this.otpState.waiting = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.mobileNumber.slice(1)}`,
        OTP: this.otpNumber,
        LineId: this.lineProfile.userId,
        IdCard: this.customerProfile.IDCard
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(`${this.$API_DEV_LINE_SKL}/ValidateOTP`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.otpState.waiting = false;

          if (result.result == 1) {
            this.getCustomerProfile();
            this.buttonLoading = false;
          } else {
            this.otpState.isError = true;
            this.otpState.otpMessage = result.message;
            this.buttonLoading = false;
          }
        })
        .catch(error => {
          this.otpState.waiting = false;
          console.error(error);
          this.buttonLoading = false;
          this.$bvModal.show("modal-tryagain");
        });
    },
    async openFamilyClub(isClick) {
      this.buttonLoading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginfamilyclub/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.$family_club = result.detail.url;
            window.open(this.$family_club, "_blank");
            // this.$emit("event-from-installment", result.detail.url);
          }

          this.buttonLoading = false;
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    },
    onScroll() {
      const scrollLeft = this.$refs.scrollContainer.scrollLeft;
      const cardWidth = this.$refs.contractList[0].clientWidth;
      this.currentIndex = Math.round(scrollLeft / cardWidth);
    },
    scrollToIndex(index) {
      const cardWidth = this.$refs.contractList[index].clientWidth;
      this.$refs.scrollContainer.scrollLeft = index * cardWidth;
      this.currentIndex = index;
    },
    openQrcode() {
      this.openQr = !this.openQr;
      let qr = this.$refs.qrCode;

      if (this.openQr) {
        this.openPayment = false;
        this.$refs.collapsePayment.show = false;
      }
      this.$refs.collapseQr.show = this.openQr;
      setTimeout(() => {
        if (qr && qr.$el) {
          window.scrollTo({
            top: document.body.clientHeight,
            behavior: "smooth"
          });
        }
      }, 100);
    },
    handleOpenPayment() {
      this.openPayment = !this.openPayment;
      let onlinePayment = this.$refs.onlinePayment;
      if (this.openPayment) {
        this.openQr = false;
        this.$refs.collapseQr.show = false;
      }
      this.$refs.collapsePayment.show = this.openPayment;

      setTimeout(() => {
        if (onlinePayment && onlinePayment.$el) {
          window.scrollTo({
            top: document.body.clientHeight,
            behavior: "smooth"
          });
        }
      }, 100);
    },
    downloadSKL() {},
    updateTitle() {
      const titles = [
        "SKL Connect - ข้อมูลสัญญา",
        "SKL Connect - ข้อมูลค่างวด",
        "SKL Connect - แฟมิลี่คลับ",
        "SKL Connect - ประวัติการชำระ",
        "SKL Connect - ตารางค่างวด",
        "SKL Connect - ใบแจ้งชำระ"
      ];
      const id = Number(this.$route.params.id) || 0;
      document.title = titles[id] || "SKL Connect";

      setTimeout(() => {
        const button = this.$refs.tabButton[id];
        if (button) {
          button.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
          });
        }
      }, 100);
    },

    beforeRouteUpdate(to, from, next) {
      this.updateTitle(); // อัปเดต title เมื่อเส้นทางถูกอัปเดต
      next();
    }
  },
  watch: {
    "$route.params.id": "updateTitle"
  }
};
</script>

<style scoped>
::v-deep .greenColor thead {
  color: white;
  background-color: rgb(128, 195, 66) !important;
}
.txt-tel {
  text-align: center;
  font-size: 12px;
  bottom: 0;
}
.txt-note {
  font-size: 10px;
}
.link-tel {
  text-decoration-line: none;
  text-align: center;
  /* color: #212529; */
  color: #f48139;
  font-weight: bold;
}
.link-tel-green {
  color: #80c141;
}
.display-inline {
  display: inline-flex;
}
.title-note {
  width: 12%;
}
.content-note {
  width: 88%;
}

::v-deep table > thead > tr > th {
  border: 0.5px solid #cdfca1;
}
::v-deep table > tbody > tr > td {
  border-left: 0.5px solid #cdfca1;
  border-right: 0.5px solid #cdfca1;
}
::v-deep tr:last-child {
  border-bottom: 0.5px solid #cdfca1;
}
@media (max-width: 767.98px) {
  .title-note {
    width: 30%;
  }
  .content-note {
    width: 70%;
  }
  .header-table,
  ::v-deep .table td {
    font-size: 12px;
  }
  ::v-deep .table th {
    padding: 0.5rem;
    font-size: 12px !important;
  }
  /* p {
    font-size: 8px;
  }
  span {
    font-size: 8px;
  } */
}

.insallment-body {
  background-image: url("../../assets/images/installment-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 600px;
  padding-bottom: 100px;
  overflow-y: auto !important;
  width: 100%;
  box-sizing: content-box !important;
  background-color: #f9fbf7;
}

/* @media (max-width: 400px) {
  .insallment-body {
    padding-bottom: 120px !important;
  }
} */

.icon-w {
  width: 20px;
  height: 20px;
}
.bg-body {
  background-color: #f9fbf7 !important;
}

.w-50px {
  width: 50px !important;
}

.submenu-tap {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.submenu1-class {
  border-radius: none !important;
  height: 35px !important;
  font-size: 12px !important;
  overflow: hidden;
  text-wrap: nowrap;
  min-width: 100px !important;
}

::v-deep .btn {
  color: #80c141;
}

.img-size {
  width: 100px;
  height: 100px;
}
/* 
.verify-body {
  position: relative;
  height: calc(100vh - 180px - 65px - 80px);
} */

.content-installment {
  box-shadow: rgba(50, 50, 93, 0.25) 2px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 0px;
  min-height: calc(100vh - 180px - 150px - 130px - 150px);
}

.content-tab {
  min-height: calc(100vh - 180px - 150px - 130px - 150px);
  box-shadow: rgba(50, 50, 93, 0.25) 2px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 0px;
}

.content-tab ::v-deep .card-body {
  padding: 1rem;
}

.barcode-card,
.payment-card {
  box-shadow: rgba(50, 50, 93, 0.25) 2px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 0px;
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  scrollbar-width: none !important;
}

.card-slide {
  border: 0cm;
  box-shadow: rgba(50, 50, 93, 0.25) 2px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  flex: 0 0 auto !important;
}

.indicator-container {
  display: flex;
  gap: 5px;
}

.indicator-dot {
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.active-dot {
  background-color: #80c141;
}
v-deep .btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.installment-bg {
  background-image: url("../../assets//images/installment-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
}

.family-container {
  min-height: 200px;
  height: 100%;
}

.f-navy {
  color: navy;
}
</style>
