<template>
  <div>
    <p class="f-medium">สัญญาสินเชื่อของท่าน</p>
    <div class="px-4">
      <div class="justify-content-between d-flex f-14">
        <p class="mt-1">เลขที่สัญญา :</p>
        <p class="f-medium">{{ contract.contractNoDisplay }}</p>
      </div>
      <div class="f-14">
        <p class="f-medium">ประเภทการชำระเงิน :</p>
        <InputSelect
          :options="paymentTypeList"
          v-model="selectedPayment"
          @onDataChange="handlePaymentChange"
        ></InputSelect>
      </div>

      <p class="f-14 f-medium">รายละเอียดการชำระเงิน :</p>

      <div class="justify-content-between d-flex f-14">
        <p class="">เลขที่อ้างอิง 1 (ref.1) :</p>
        <p class="font-weight-bold">{{ numberRef }}</p>
      </div>

      <div class="justify-content-between d-flex f-14">
        <p class="">ยอดเงินที่ต้องชำระ</p>
        <p class="font-weight-bold">{{ totalAmount }}</p>
      </div>
      <div class="mt-2 d-flex alihn-items-center text-center">
        <div :class="['mr-2 mt-2', { 'mb-4': $v.form.total.$error }]">
          <svg
            class="svg-check-box"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            @click="form.isEdit = !form.isEdit"
          >
            <g
              id="Rectangle_1784"
              data-name="Rectangle 1784"
              fill="#fff"
              stroke="#707070"
              stroke-width="1"
            >
              <rect width="18" height="18" rx="3" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="18"
                height="18"
                rx="2.5"
                fill="none"
              />
            </g>
            <path
              id="Path_8841"
              data-name="Path 8841"
              d="M-18087.189-17236.355l5.17,5.17,6.658-11"
              transform="translate(18093 17248)"
              fill="none"
              stroke="#80c141"
              stroke-linecap="round"
              stroke-width="2"
              v-if="form.isEdit"
            />
          </svg>
        </div>
        <div class="flex-grow-1">
          <InputTextBox
            class="custom-text-box"
            id="input-1"
            v-model="form.total"
            :placeholder="
              !['SP', 'L'].includes(contract.collectionStatusUnits)
                ? 'แก้ไขยอดที่ต้องการชำระ'
                : '-'
            "
            type="text"
            inputmode="decimal"
            :isError="form.isError"
            :isDisabled="!form.isEdit"
            inputChecker="currency"
          />
          <div id="input-error" class="pl-2 mt-1" v-if="$v.form.total.$error">
            <font color="red">ยอดเงินชำระขั้นต่ำ 1 บาท</font>
          </div>
        </div>
        <!-- :changeColorPlaceholder=" ['SP',
            'L'].includes(customerProfile.CollectionStatusUnits) " -->
      </div>

      <div class="payment mt-2">
        <p class="mb-0 f-medium">เลือกธนาคารที่ท่านต้องการใช้บริการ</p>
        <p class="f-small-gray">
          กรุณาเข้าสู่ระบบโมบายแบงก์กิ้งของท่าน เพื่อดำเนินการชำระเงินให้สมบรูณ์
        </p>
        <div class="from-detail">
          <div class="detail-item">
            <b-form-group>
              <b-form-radio-group
                v-model="form.bankId"
                v-for="(option, index) in options"
                v-bind:key="index"
                size="sm"
                stacked
                plain
                class="mt-2 f-14"
              >
                <b-form-radio :value="index" class="d-flex f-14 mt-2">
                  <img :src="option.imageUrl" class="icon-bank mt-0" />
                  {{ option.name }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <b-button
        id="submit-payment"
        
        class="w-100  btn-green"
        type="button"
        @click="submitForm"
        :disabled="buttonLoading"
      >
        <b-spinner small v-if="buttonLoading"></b-spinner>
        <span v-else>ชำระเงิน</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../../components/inputs/InputSelect.vue";
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
import { required, requiredIf, decimal } from "vuelidate/lib/validators";

export default {
  components: { InputSelect, InputTextBox },
  props: {
    idCard: { required: false, type: String },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
    // displayContractNo:{
    //   required: true,
    //   type: String
    // }
  },
  validations() {
    return {
      form: {
        total: {
          required: requiredIf(function () {
            return this.form.isEdit;
          })
        },
        bankId: { required, decimal }
      }
    };
  },
  data: () => {
    return {
      lineId: "",

      callCenter: "",
      form: {
        bankId: null,
        total: "",
        isEdit: false,
        isError: false
      },
      paymentTypeList: [
        { text: "ค่างวด", value: "1003" },
        { text: "ค่าติดตามทวงถาม", value: "4103" },
        { text: "ค่าเบี้ยปรับ", value: "4090" },
        {
          text: "ค่าภาษีและค่าบริการ พ.ร.บ.",
          value: "2001"
        }
      ],
      selectedPayment: "1003",
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      numberRef: "",
      totalAmount: 0.0,

      barcodeUrl: {},
      options: [],
      buttonLoading: false,
      otherOptions: [
        { src: "counter.png", alt: "" },
        { src: "lotus.png", alt: "" },
        { src: "thaipost.png", alt: "" },
        { src: "mpay.png", alt: "" }
      ],
      contractNo: ""
    };
  },
  created() {},
  methods: {
    setPaymentType(contractType) {
      if (contractType === "PL") {
        this.paymentTypeList = [{ text: "ยอดชำระเงินรวม", value: "1021" }];
        this.selectedPayment = "1021";
      } else if (contractType === "TL") {
        this.paymentTypeList = [{ text: "ยอดชำระเงินรวม", value: "1031" }];
        this.selectedPayment = "1031";
      }
    },
    async getOnlinePayment(contact, contractType) {
      this.setPaymentType(contractType);
      this.form = {
        bankId: null,
        total: "",
        isEdit: false,
        isError: false
      };
      this.contractNo = contact;
      await this.getPaymentAmount(contact);
      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
        requestOptionsBankList
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.options = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // getContactBarcode() {
    //   var myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");

    //   var raw = JSON.stringify({
    //     Authorization: this.$NIMBUS_TOKEN,
    //     RequestBody: JSON.stringify({
    //       ContractNo: this.contract.contractNo
    //     }),
    //     Path: `${this.$API_DEV_SKL}/contract_barcode`,
    //     Method: "POST"
    //   });

    //   var requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow"
    //   };

    //   fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
    //     .then(response => response.json())
    //     .then(result => {
    //       if (result.result) {
    //         this.numberRef = result.data.BankBarcodeRef1;

    //         this.barcodeUrl = result.url;
    //         this.changeNumberRef(result.data.BankBarcodeRef1);
    //       }
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },
    async getPaymentAmount(contract) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      var raw = JSON.stringify({
        ContractNo: contract,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/payment/Amount`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.totalAmount = result.detail.amount;
            this.form.total = ["SP", "L"].includes(
              this.contract.collectionStatusUnits
            )
              ? ""
              : result.detail.amount;
          } else {
            this.totalAmount = "0.00";
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeNumberRef(numberRef) {
      let indexContractNo = numberRef.indexOf(this.contract.contractNo);
      let lengthContractNo = this.contract.contractNo.length;
      this.numberRef = numberRef.substring(
        0,
        indexContractNo + lengthContractNo
      );
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.bankId.$error) {
        this.$bvModal.show("bankAlert");
        return;
      } else if (this.$v.form.$error) {
        return;
      }
      this.form.isError = false;
      var userPay;
      if (this.form.isEdit) {
        if (parseFloat(this.form.total) < 1.0) {
          this.form.isError = true;
          return;
        } else {
          userPay = this.form.total.toString();
        }
      } else {
        userPay = this.form.total.toString();
      }

      this.buttonLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contractNo,
          PaymentBank: this.options[this.form.bankId].code,
          PaymentAmount: parseFloat(userPay.replace(/,/g, "")).toString(),
          IdCard: this.idCard,
          ChannelID: 2
        }),
        Path: `${this.$API_DEV_SKL}/payment_online`,
        Method: "POST",
        type: this.type,
        LineId: this.lineProfile.userId
      });

      console.log(
        "RequestBody PortalPaymentOnline: " +
          JSON.stringify({
            ContractNo: this.contractNo,
            PaymentBank: this.options[this.form.bankId].code,
            PaymentAmount: parseFloat(userPay.replace(/,/g, "")).toString(),
            IdCard: this.idCard,
            ChannelID: 2
          })
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/PortalPaymentOnline`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.buttonLoading = false;
          if (result.result && result.message !== "ไม่สำเร็จ") {
            this.pay_onlineResponse = result.data;
            console.log(
              "result PortalPaymentOnline" + JSON.stringify(result.data)
            );
            this.state = "payPage";
            this.$nextTick(() => {
              this.$refs.submit.click();
              this.form.bankId = null;
            });
          } else {
            this.$bvModal.show("modal-tryagain");
          }
        })
        .catch(error => {
          this.buttonLoading = false;
          this.$bvModal.show("modal-tryagain");
          console.error(error);
        });
    },
    handlePaymentChange(val) {
      this.selectedPayment = val;
      this.getOnlinePayment(this.contractNo);
    }
  }
};
</script>

<style scoped>
.barcode-size {
  width: 100px;
  height: 100px;
}

.f-10 {
  font-size: 10px !important;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}

.icon-bank {
  height: 40px;
  background-image: none !important;
}

@media (max-width: 576px) {
  .icon-bank {
    height: 30px !important;
  }

  .icon-pay {
    height: 30px !important;
  }
}

.icon-pay {
  height: 40px;
}

::v-deep input[type="radio"] {
  margin-top: 10px !important;
}

.custom-text-box ::v-deep input {
  font-size: 12px !important;
  margin-top: 6px;
  height: 32px !important;
}
</style>
