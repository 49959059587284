<template>
  <div>
    <p class="f-medium">สัญญาสินเชื่อของท่าน</p>
    <div class="px-4">
      <div class="justify-content-between d-flex f-14">
        <p>เลขที่สัญญา :</p>
        <p class="f-medium">{{ contract.contractNoDisplay }}</p>
      </div>
      <div class="f-14">
        <p class="f-medium">ประเภทการชำระ :</p>
        <InputSelect
          :options="paymentTypeList"
          v-model="selectedPayment"
          @onDataChange="handlePaymentChange"
        ></InputSelect>
      </div>

      <div
        class="justify-content-between d-flex f-14 font-weight-bold"
        v-if="contract.collectionStatusUnits !== 'SP'"
      >
        <p class="f-medium">ยอดเงินที่ต้องชำระ :</p>
        <p>{{ totalAmount }}</p>
      </div>
      <div class="barcode-container px-4 f-12 text-center mt-4">
        <p class="font-weight-bold">บาร์โคด/คิวอาร์โคด ชำระเงินของท่าน</p>
        <p>รหัสอ้างอิง 1/ Ref.1: {{ barcodeImg.ref1 }}</p>
        <div>
          <img
            :src="barcodeImg.filePathbarcodeNotFlip"
            alt="barcode"
            class="barcode-size mt-2"
          />
        </div>

        <p class="f-10">{{ barcodeImg.barcodeBankText }}</p>
        <div>
          <img
            :src="barcodeImg.filePathQrCode"
            alt="qr-code"
            class="qr-size mt-2"
          />
        </div>
        <p class="f-10">{{ barcodeImg.qrCodeBankText }}</p>
        <!-- <div class="mt-3">
          <a href="#" class="f-orange text-link"
            >คลิกที่รูปบาร์โคดคิวอาร์โคดเพื่อขยาย</a
          >
        </div> -->
      </div>
    </div>
    <hr />
    <div class="barcode-container px-4 f-14 text-center mt-4">
      <p class="font-weight-bold">วิธีการชำระเงินด้วยบาร์โคด/คิวอาร์โคด</p>
      <p class="mt-2 f-12">
        หลังจากบันทึกภาพบาร์โคด/คิวอาร์โคดเรียบร้อยแล้ว <br />
        สามารถเลือกชำระเงินผ่านการสแกน<br />
        ตามช่องทางการชำระเงินด้านล่าง
      </p>

      <b-card class="bg-gray mt-2">
        <p>ชำระผ่านธนาคารที่ร่วมให้บริการ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <a
            v-for="(option, index) in options"
            :key="index"
            :href="option.linkUrl"
            :alt="option.name"
          >
            <img :src="option.imageUrl" class="icon-bank px-1"
          /></a>
        </div>

        <p class="mt-2">ช่องทางการชำระอิ่นๆ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <img
            v-for="(option, index) in otherOptions"
            :key="index"
            :src="option.imageUrl"
            :alt="option.name"
            class="icon-pay px-1"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../../components/inputs/InputSelect.vue";

export default {
  components: { InputSelect },
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data: () => {
    return {
      lineId: "",

      callCenter: "",

      paymentTypeList: [
        { text: "ค่างวด", value: "1003" },
        { text: "ค่าติดตามทวงถาม", value: "4103" },
        { text: "ค่าเบี้ยปรับ", value: "4090" },
        {
          text: "ค่าภาษีและค่าบริการ พ.ร.บ.",
          value: "2001"
        }
      ],
      selectedPayment: "1003",
      paymentAmount: 0,
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      numberRef: "",
      contractInfo: {},
      barcodeUrl: {},
      barcodeImg: {},
      options: [],
      otherOptions: [],
      contractNo: "",
      totalAmount: null
    };
  },
  created() {},
  methods: {
    setPaymentType(contractType) {
      if (contractType === "PL") {
        this.paymentTypeList = [{ text: "ยอดชำระเงินรวม", value: "1021" }];
        this.selectedPayment = "1021";
      } else if (contractType === "TL") {
        this.paymentTypeList = [{ text: "ยอดชำระเงินรวม", value: "1031" }];
        this.selectedPayment = "1031";
      }
    },
    getBarcodePayment() {
      // var raw = JSON.stringify({
      //   Authorization: this.$NIMBUS_TOKEN,
      //   RequestBody: JSON.stringify({
      //     ContractNo: this.contract.contractNo
      //   }),
      //   Path: `${this.$API_DEV_SKL}/contract_installment`,
      //   Method: "POST"
      // });

      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: "follow"
      // };

      // fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
      //   .then(response => response.json())
      //   .then(result => {
      //     if (result.result) {
      //       this.contractInfo = result.data;
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });

      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
        requestOptionsBankList
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.options = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });

      this.getOtherPaymentOptions();
    },
    getOtherPaymentOptions() {
      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment/others`,
        requestOptionsBankList
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.otherOptions = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getContactBarcodeImg(contractNo, contractType) {
      this.setPaymentType(contractType);
      this.$isLoadingAlpha = true;
      this.contractNo = contractNo;
      await this.getPaymentAmount(contractNo);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var raw = JSON.stringify({
        ContractNo: contractNo,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/payment/QRCodeByTranCode`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.barcodeImg = result.detail;
          } else {
            this.barcodeImg = {};
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    async getPaymentAmount(contract, contractType) {
      this.setPaymentType(contractType);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      var raw = JSON.stringify({
        ContractNo: contract,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${this.$API_DEV_LINE_SKL}/payment/Amount`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.totalAmount = result.detail.amount;
          } else {
            this.totalAmount = "0.00";
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    handlePaymentChange(val) {
      this.selectedPayment = val;
      this.getContactBarcodeImg(this.contractNo);
    }
  }
};
</script>

<style scoped>
.barcode-size {
  width: 100%;
  height: 60px;
}

.qr-size {
  width: 60px;
  height: 60px;
}

.f-10 {
  font-size: 10px !important;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}

.icon-bank {
  height: 40px;
  border-radius: 50%;
  background-image: none !important;
}

@media (max-width: 576px) {
  .icon-bank {
    height: 30px !important;
  }

  .icon-pay {
    height: 30px !important;
  }
}

.icon-pay {
  height: 40px;
  border-radius: 50%;
}
</style>
