<template>
  <div :class="['select-custom', className, { error: isValidate }]">
    <label v-if="title">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <br v-if="title" />
    <b-form-select
      v-bind:options="options"
      :name="name"
      :size="size"
      v-bind:value="value"
      :required="required"
      :class="['btn-select']"
      @change="onDataChange"
      :valueField="valueField"
      :textField="textField"
      :disabled="disabled"
    >
      <template #first>
        <slot name="option-first"></slot>
      </template>
    </b-form-select>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        {{ validateText || "กรุณาเลือกข้อมูล" }}
      </span>
      <!-- <span class="text-error" v-else-if="v.required == false"
          >{{ options[0][textField || "text"] }} asdf</span
        > -->
      <span class="text-error" v-else-if="v.minValue == false">{{
        options[0][textField || "text"]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: [Number, String]
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    disabled: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    valueField: {
      required: false,
      type: String
    },
    textField: {
      required: false,
      type: String
    },
    validateText: {
      required: false,
      type: String
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    }
  }
};
</script>

<style scoped>
.select-custom {
  margin-bottom: 0.5rem;
  position: relative;
  white-space: nowrap;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}
.select-custom > label {
  color: #333 !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
::v-deep .btn-select {
  font-size: 12px !important;
  color: #404040;
  background-color: white;
 
}
::v-deep .btn-select.custom-select-lg {
  height: 55px;
  font-size: 1rem;
}
/* ::v-deep .custom-select {
    height: 100%;
  } */
::v-deep .btn-select:focus {
  /* border-color: #16274a; */
  border-color: #80c141;
}
::v-deep .btn-select > option {
  background-color: white;
  color: #16274a;
}
::v-deep .btn-select > option:checked {
  /* background-color: #16274a !important;  */
  /* background-color: #80c141 !important; */
  /* color: white !important; */
  color: #16274a !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}

@media (max-width: 767.98px) {
  .select-custom {
    margin-top: 0;
  }
  .select-custom > label {
    font-size: 15px;
  }
}
</style>
